import { React, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { shareAPI } from '../../services'
import './grid.scss'
import placeholderImg from './placeholder.png'
import Masonry from 'react-masonry-css'
import { useHistory } from 'react-router-dom'
import { Button } from 'primereact/button'
import { fetchConfig } from '../../store/configSlice'

const GridItem = ({ item, filter }) => {
  const history = useHistory()

  const dispatch = useDispatch()
  const { config } = useSelector(state => state.config)

  useEffect(() => { if (config == null || config == undefined) dispatch(fetchConfig()); }, [dispatch])

  const { categories } = useSelector(state => state.salon)

  const getFirstImage = () => {
    const t = typeof (item.salon_images) != 'undefined';
    const t2 = item.salon_images.length > 0;
    if (t && t2) { 
      return item.salon_images[0].image
    }
    return placeholderImg;
      //return (typeof(item.salon_images) != 'undefined' && item.salon_images.length > 0) ? item.salon_images[0].image : placeholderImg
  };

  const GetCategoriesImages = () => { // muestra los iconos de categorias de cada salon
    let srcIconCategories = [];
    if (item.categories != undefined && item.categories.length > 0) {
      for (var i = 0; i < item.categories.length; i++) {
        let currentSalonCat = item.categories[i];
        for (var j = 0; j < categories.length; j++) {
          if (currentSalonCat == categories[j].filter.category) {
            if (filter.category == categories[j].filter.category) {
              srcIconCategories.push(categories[j].image_selected);
            } else {
              srcIconCategories.push(categories[j].image);
            }           
          }
        }
      }

    }
    if (srcIconCategories.length > 0) {

      return (
        <div className="row">
          {
            (srcIconCategories).map(function (catSrc, key) {
              return (

                <div key={key}> <img className="icon-grid-category" src={catSrc} /></div>

              )
            })
          }
        </div>
      );

    }
    else
      return (<></>)
    return null;
  }

  const GetCountryState = ({ salon }) => {
    let concatUbication = "";
    let cant = 0;


    if (salon.neighborhood != null) {
      concatUbication = salon.neighborhood.name; cant++;
    }
    if (salon.municipality != null) {
      concatUbication = concatUbication.length > 0 ? (salon.municipality.name + " - " + concatUbication) : salon.municipality.name; cant++;
    }
    if (salon.state != null) {
      concatUbication = concatUbication.length > 0 ? (salon.state.name + " - " + concatUbication) : salon.state.name; cant++;
    }
    if (salon.country != null && cant < 3) {
      concatUbication = concatUbication.length > 0 ? (salon.country.name + " - " + concatUbication) : salon.country.name; cant++;
    }


    return (

      <span className="product-country">
        {concatUbication}
      </span>
    )
  }

  const openWhatsApp = (phoneNumber, message = 'Hola, le contacto desde la *app Qtokao Suite*, Me interesa obtener información de sus servicios!') => {
    shareAPI.shareWhatsApp(phoneNumber, message)
  }

  const shareSalon = (salon) => {
    let basicInformation = `${salon.name}\nTeléfono\n${salon.phone_number}\nDirección\n${salon.address}`
    if (salon.facebook != null) {
      basicInformation += `\nFacebook\n${salon.facebook}`
    }
    if (salon.instagram != null) {
      basicInformation += `\nInstagram\n${salon.instagram}`
    }
    basicInformation += `\nCon la app Qtokao Suite  puedes encontrar este y muchos otros salones, Descárgatela en el siguiente link:\n${config.google_play_app_url}`
    shareAPI.shareText(basicInformation)
  }

  const callSalon = (number) => {
    window.plugins.CallNumber.callNumber(console.log, console.log, number, true)
  }

  return (
    <div className="product-item p-shadow-3 p-my-2 p-flex-column">
      <div className="image-container" onClick={() => history.push(`/salon/${item.id}`)}>
        <img src={getFirstImage()} onError={(e) => {
          e.target.src = placeholderImg;
          console.log(e)
        }
        }   alt="probando" />
      </div>
      <div className="product-detail p-px-2" onClick={() => history.push(`/salon/${item.id}`)}>
        <h5 className="p-mb-2 salon-title">{item.name}</h5>

        <GetCountryState salon={item} />
        <text className="product-category">
        {item.address}
        </text>
        <GetCategoriesImages />
      </div>
    </div>
  )
}

const breakpointColumnsObj = {
  default: 4,
  950: 3,
  700: 2,
  350: 1
};

const Grid = ({ items, filter }) => {
  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="catalogue-grid"
      columnClassName="catalogue-grid_column">
      {
        items.map((value, index) => <GridItem key={value.id} item={value} filter={filter} />)
      }
    </Masonry>
  )
}

export default Grid
