import React from 'react'
import { shareAPI } from '../../services'
import { useHistory } from 'react-router-dom'
import './index.scss'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'

import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { fetchConfig } from '../../store/configSlice' 
import RegisterLogin  from "../registerLogin";
import Search from '../../components/search'


const BottomMenu = () => {
  const history = useHistory()


const dispatch = useDispatch()
const { config } = useSelector(state => state.config )

useEffect(() => {
  if(config == undefined || config == null) dispatch (fetchConfig()); }, [dispatch])


  const scan = () => {
    history.push('/qrscanner')
  }

  const shareAppLink = () => {
    shareAPI.shareLink('Qtokao App', 'Conoce los emprendedores cubanos en la belleza cosmética y cuidado personal, en nuestra aplicación. Te comparto el link de la app Qtokao Suite', config.google_play_app_url)
  }

  const goToCatalog = () => {
    history.replace('/')
    
  }

  const goToAboutUs = () => {
    history.push('/aboutus')
  }


  const leftContents  = (
    <>
    

      {/* <Button label="Leer QR" icon="pi pi-microsoft" className="p-button-text p-d-flex p-flex-column p-button-bottom" onClick={() => scan()} />  */}
      <Button label="Compartir" icon="pi pi-share-alt" className="p-button-text p-d-flex p-flex-column p-button-bottom" onClick={() => shareAppLink()} />
      <Search/>
      <Button label="Negocios" icon="pi pi-briefcase" className="p-button-text p-d-flex p-flex-column p-button-bottom" onClick={() => goToCatalog()} />
      <Button label="Nosotros" icon="pi pi-users" className="p-button-text p-d-flex p-flex-column p-button-bottom" onClick={() => goToAboutUs()} />   
       
      {/* <RegisterLogin /> ToDo Enviar a Login cuando token invalido, desloguear, registro con Gmail, Registro con Correo, Probar Todo*/}

    </>
  )

  return (
    <div className="bottom-menu">
      <Toolbar left={leftContents} />
    </div>
  )
}

export default BottomMenu
