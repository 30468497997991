import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import store from './store'
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals'
import './config';

const renderReactDom = () => {
    ReactDOM.render(
      (
      <Provider store={store}>
        <App />
      </Provider>
      ), document.getElementById('root'))
}

navigator.serviceWorker.register('sw.js')
  .then(reg => {
    console.log('Service Worker registered successfully')
  })
  .catch(e => {
    console.error('Error registering service worker:', e)
  })

if (window.cordova) {
    document.addEventListener('deviceready', () => {
   //  window.open = cordova.InAppBrowser.open;

        renderReactDom()
    }, false)
}else{
//  window.open = cordova.InAppBrowser.open;

    renderReactDom()
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
