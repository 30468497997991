import React, { useEffect, useState } from 'react'
import { shareAPI } from '../../services'
import './index.scss'
import placeholderImg from './placeholder.png'
import ShowMore from 'react-show-more'
import { Divider } from 'primereact/divider'
import { Carousel } from 'primereact/carousel'
import { Button } from 'primereact/button'
import { useParams } from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux'
import BackButton from '../../components/backbutton'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {fab, faFacebook, faInstagram, faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {faPhoneVolume,faGlobe, faShareAlt} from "@fortawesome/free-solid-svg-icons";
//Locale
import {I18Provider, LOCALES } from '../../i18n';
import { FormattedMessage } from 'react-intl';

import { fetchConfig } from '../../store/configSlice' 
import { fetchSalon } from '../../store/salonSlice'
library.add(fab,faFacebook,faInstagram,faWhatsapp,faPhoneVolume,faGlobe,faShareAlt); 



const CatalogueDetail = () => {
  const { id } = useParams()
  let { salons, salon } = useSelector(state => state.salon)

  if (salons && salons.length > 0) {
    salon = salons.find(item => item.id == id)
  }
 
  const images = salon && (typeof (salon.salon_images) != 'undefined') ? salon.salon_images.map(image => image.image) : []

  const GetCountryState = ({ salon }) => {
    let concatUbication = "";
    let cant = 0;


    if (salon.neighborhood != null) {
      concatUbication = salon.neighborhood.name; cant++;
    }
    if (salon.municipality != null) {
      concatUbication = concatUbication.length > 0 ? (salon.municipality.name + " - " + concatUbication) : salon.municipality.name; cant++;
    }
    if (salon.state != null) {
      concatUbication = concatUbication.length > 0 ? (salon.state.name + " - " + concatUbication) : salon.state.name; cant++;
    }
    if (salon.country != null && cant < 3) {
      concatUbication = concatUbication.length > 0 ? (salon.country.name + " - " + concatUbication) : salon.country.name; cant++;
    }


    return (

      <p className="bussiness-country">
        {concatUbication}
      </p>
    )
  }

  const dispatch = useDispatch()
  const { config } = useSelector(state => state.config)
  
  useEffect(() => {
    if (!salons || !salons.length)
    dispatch(fetchSalon(id));
  }, [])

  useEffect(() => {
    dispatch(fetchConfig());
    //dispatch(fetchSalon(id));
  }, [dispatch])


  if (images.length === 0) {
    images.push(placeholderImg)
  }
  
  const openWhatsApp = (phoneNumber, message = 'Hola, le contacto desde la *app Qtokao Suite*. Me interesa obtener información de sus servicios!') => {
    shareAPI.shareWhatsApp(phoneNumber, message)
  }

  const shareSalon = (item) => {
    let basicInformation = `Conoce a este salón tokaísimo:`
    basicInformation += `\n*${item.name}*\n📞 Teléfono\n${item.phone_number}\n📌 Dirección\n${item.address}`;
    /* if (item.facebook != null) {
       basicInformation += `\n👍 Facebook\n${item.facebook}`
     }
     if (item.instagram != null) {
       basicInformation += `\n📷 Instagram\n${item.instagram}`
     }*/
    basicInformation += `\n\n💡 DESCARGA la *app Qtokao Suite* , conoce los emprendedores cubanos 🇨🇺 en la belleza y cuidado personal.\n\n🤳 Aquí el link 👇 :\n${config.google_play_app_url}`;
    shareAPI.shareText(basicInformation)
  }

  const getSalonShareMessage = (item) => { 
    // let basicInformation = `Conoce a este salón tokaísimo:`
    // basicInformation += `\n*${item.name}*\n📞 Teléfono\n${item.phone_number}\n📌 Dirección\n${item.address}`;
    // /* if (item.facebook != null) {
    //    basicInformation += `\n👍 Facebook\n${item.facebook}`
    //  }
    //  if (item.instagram != null) {
    //    basicInformation += `\n📷 Instagram\n${item.instagram}`
    //  }*/
    // basicInformation += `\n\n💡 DESCARGA la *app Qtokao Suite* , conoce los emprendedores cubanos 🇨🇺 en la belleza y cuidado personal.\n\n🤳 Aquí el link 👇 :\n${config.google_play_app_url}`;
    return 'Hola, le contacto desde la *app Qtokao Suite*, Me interesa obtener información de sus servicios para mi negocio!';

  }

  const itemTemplate = (item) => {
    return <img src={item} alt="Test test" style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto', position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto', textAlign: 'center'/*, display: 'block'*/ }} />
  }

  const callSalon = (number) => {
    window.plugins.CallNumber.callNumber(console.log, console.log, number, true)
  }

  return (
     salon && (
      <div className="catalogue-detail">
        <BackButton />
        <Carousel value={images} className="catalogue-detail-carousel" numVisible={1} numScroll={1} autoplayInterval={3000} circular={true} itemTemplate={itemTemplate} />
        <div className="card">
          <h3 className="p-m-2 name-detail" >
            {salon.name}
       
            <GetCountryState salon={salon} />
            <p className="bussiness-address">{salon.address}</p>
          </h3>
          <p className="p-m-2 description-detail">
            <ShowMore lines={3} more="Ver más" less="Ver menos" anchorClass="show-more-anchor">
              {salon.description}
            </ShowMore>
          </p>
          <h4 className="p-m-2" style={{ 'fontSize': '5vw' }}>Contacto y Horario</h4>
          <Divider />
          <div className="p-m-2 bussiness-list">
            <div className="contact-item">
              <span style={{ 'fontSize': '4.8vw' }} >{salon.phone_number}</span>
              <span className="socialMediaLinks">
              
                <FontAwesomeIcon icon={['fas', 'share-alt']} className="p-button-rounded p-button-info p-mx-1.8" onClick={() => shareSalon(salon)} />
                <FontAwesomeIcon icon={['fas', 'phone-volume']} className="p-button-rounded p-button-info p-mx-1.8" onClick={() => callSalon(salon.phone_number)} />
                <FontAwesomeIcon icon={['fab', 'whatsapp']} className="p-button-rounded p-button-info p-mx-1.8" onClick={() => openWhatsApp(salon.phone_number, getSalonShareMessage(salon))} />
                {salon.facebook != null && <FontAwesomeIcon icon={['fab', 'facebook']} className="p-button-rounded p-button-info p-mx-1.8" onClick={() => shareAPI.shareLink(salon.facebook)} />}
                {salon.instagram != null && <FontAwesomeIcon icon={['fab', 'instagram']} className="p-button-rounded p-button-info p-mx-1.8" onClick={() => shareAPI.shareLink(salon.instagram)} />}
                {salon.web_page != null && <FontAwesomeIcon icon={['fas', 'globe']} className="p-button-rounded p-button-info p-mx-1" onClick={() => shareAPI.shareLink(salon.web_page)} />}
        
              </span>
            </div>
          </div>
          <Divider />
          <ul className="p-m-2 bussiness-list">
            {salon?.horary_intervals?.slice(0)?.reverse()?.map(horary => (
         
              <li>
                <span>{horary.workday}</span> <span>{horary.opening_time} - {horary.closing_time}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>)  || <div></div>
  )
}

export default CatalogueDetail

