import React from 'react'
import './promotions.scss'
import { Carousel } from 'primereact/carousel'

const itemTemplate = (item) => {
  return <a href={`${item.link}`}> <img src={`${item.image}`} alt="Test test" style={{ maxWidth: '100%', maxHeight: '100%',width : 'auto', height: 'auto',position: 'absolute',top:'0',bottom:'0',left:'0',right:'0',margin:'auto',textAlign:'center' }} /></a>
}

const Promotions = ({advertisements}) => {
  const NUM_VISIBLE = 1;
  const NUM_SCROLL = 1;
  const AUTOPLAY_INTERVAL = 10000;

  return (
    <Carousel value={advertisements} className="promotions-carousel" numVisible={NUM_VISIBLE} numScroll={NUM_SCROLL} itemTemplate={itemTemplate} autoplayInterval={AUTOPLAY_INTERVAL} />
  )
}

export default Promotions
