import axios from "axios";
import { BASE_URL } from "../config";

const getSalons = async (page, filter={}) => {
  
  let urlFilter = Object.keys(filter).map(f => {
    return `${f}=${filter[f]}`;
  }).join('&')
let pageF = page != null && page != "" ? `&page=${page}` : "";
  return axios.get(`${BASE_URL}/salon/salon/?is_active=true${pageF}&${urlFilter}`)
}

const getSalon = (id) => { 
  return axios.get(`${BASE_URL}/salon/salon/${id}/`)
}

/*const getSalonsSearch = async (search) => {

  return axios.get(`${BASE_URL}/salon/salon/?is_active=true&search=${search}`)
}*/
/*
const createSalon= async(data) => {
  await axios.post(BASE_URL, data)
  .then(response=>{
    setData(data.concat(response.data))
    abrirCerrarModalInsertar()
  })
}*/

export default {
  getSalon,
  getSalons/*,
  getSalonsSearch*/
}


