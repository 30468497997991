import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { configAPI } from '../services'

 const initialState = {
  hasErrors: false,
  loading: false,
  config: {}
} 


export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    loadConfig: state => {
      state.loading = true
   
    },
    loadConfigSuccess: (state,{payload}) => {
      state.config = payload
      state.loading = false
      state.hasErrors = false

    },
    loadConfigFailure: state => {
      state.loading = false
      state.hasErrors = true
    }
  }
})

export const fetchConfig = () => {
  return async dispatch => {
    dispatch(loadConfig())
    try {
    
      const response = await configAPI.getConfig()
    
      dispatch(loadConfigSuccess(response.data))
      return response
    } catch (error) {
     
      dispatch(loadConfigFailure())
      throw error
    }
  }
}

export const { loadConfig, loadConfigSuccess, loadConfigFailure } = configSlice.actions;
export const configSliceSelector = state => state.config
export default configSlice.reducer


