import React from 'react'
import './App.scss';
import './assets/fonts/style.scss'
import {
  HashRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom"
import QRScanner from './views/qrscanner'
import AboutUs from './views/aboutus'
import Perfil from './views/perfil'
import CatalogueView from './views/catalogue'
import CatalogueDetailView from './views/cataloguedetail'
import { createBrowserHistory } from 'history'
import BottomMenu from './components/bottommenu'
// React Notification
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
//Locale
import { I18Provider, LOCALES } from './i18n';
const customHistory = createBrowserHistory()


function App() {
  return (
    <I18Provider locale = {LOCALES.SPANISH}>
  
    <Router history={customHistory}>
     
        <Switch>
          <Route exact path="/">
            <CatalogueView />
          </Route>
          <Route path="/salon/:id">
            <CatalogueDetailView />
          </Route>
          <Route path="/qrscanner">
            <QRScanner />
          </Route>
          <Route path="/aboutus">
            <AboutUs />
            </Route>
            <Route path="/perfil/:access_token">
            <Perfil />
          </Route>
        </Switch>
        <NotificationContainer />
      
      <BottomMenu />
    </Router>

    </I18Provider>
  );
}

export default App;
