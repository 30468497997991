import axios from "axios";
import { BASE_URL } from "../config";

const getConfig = async () => {
    return axios.get(`${BASE_URL}/generic/config`)
}

export default {
    getConfig
}

