import './index.scss'
import React, { Component }  from 'react';
import headerImg from './header.jpg'
import { Button } from 'primereact/button'
import translate from '../../i18n/translate';



const NavBar = ({ clickHandler }) => {
  return (
    <div className="header-component">
      <img src={headerImg} />
     
      <Button style={{"fontSize": "1.3vh",  "textTransform": "uppercase"}} className="p-button addme-btn" label= {translate('inscribe')} icon="pi pi-home" iconPos="right" onClick={clickHandler} />
   
    
    </div>
  )
};

export default NavBar;
