import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { salonAPI } from '../services'
import Categories from '../categories'


//testing new token
 const initialState = {
  loading: false,
  hasErrors: false,
  count: 0,
  next: true,
  previous: null,
  salons: [],
   categories: Categories,
  salon: undefined,
  page: 1,
  filter: {}
} 

export const createSalon = createAsyncThunk(
  "salon/salon",
  async ({ data }) => {
    const res = await salonAPI.create({ data });
    return res.data;
  }
);

export const salonSlice = createSlice({
  name: 'salons',
  initialState,
  reducers: {
    loadSalons: state => {
      state.loading = true
   
    },
    loadSalonsSuccess: (state, { payload }) => {
      state.salons = [...state.salons, ...payload.results]
      state.count = payload.count
      state.next = !(payload.next === undefined || payload.next === null)
      state.previous = !(payload.previous === undefined || payload.previous === null)
      state.loading = false
      state.hasErrors = false
      
     
    },
    loadSalonsFailure: state => {
      state.loading = false
      state.hasErrors = true
    },
    loadSingleSalon: state => {
      state.loading = true
   
    },
    loadSingleSalonSuccess: (state, { payload }) => {
      state.salon = payload
      state.loading = false
      state.hasErrors = false
    },
    loadSingleSalonFailure: state => {
      state.loading = false
      state.hasErrors = true
    },
    resetSalon: state => {
      state.salons = []
     // state.page = 1
    },
    addOneSalon: (state, { payload }) => {
      state.salons = payload
      state.count = (payload.count === undefined || payload.count === null) ? null : payload.count
     // state.next = (payload.next === undefined || payload.next === null) ? null : payload.next
      //state.previous = (payload.previous === undefined || payload.previous === null) ? null : payload.previous
      state.loading = false
      state.hasErrors = false
      
    },
    setPage: state => {
      state.page = state.page + 1
      
    },
    setFilter: (state, { payload }) => {     
      state.filter = payload.filter;
    }
  },
  extraReducers: {
    [createSalon.fulfilled]: (state, action) => {
      state.push(action.payload);
    }
  }
})


export const fetchSalon = (salonId) => {
  return async dispatch => {
    try {
      const response = await salonAPI.getSalon(salonId)

      dispatch(loadSingleSalonSuccess(response.data))
      return response
    } catch (error) {

      dispatch(loadSingleSalonFailure())
      throw error
    }
  }
}

export const fetchSalons = (page, filter) => {
  filter = filter == null || Object.keys(filter).length === 0 ? { category: 4} : filter;
  console.log("filter", filter)
  return async dispatch => {
    dispatch(loadSalons())
    try {
      const response = await salonAPI.getSalons(page, filter)

      dispatch(loadSalonsSuccess(response.data))
      return response
    } catch (error) {

      dispatch(loadSalonsFailure())
      throw error
    }
  }
}

export const { loadSalons, loadSalonsSuccess, loadSalonsFailure, resetSalon,
  setPage, setFilter, addOneSalon, loadSingleSalonSuccess, loadSingleSalonFailure } = salonSlice.actions;
export const salonsSelector = state => state.salons
export default salonSlice.reducer


