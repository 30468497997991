import $ from 'jquery';

const shareLink = (title, text, link) => {
  //  window.plugins.socialsharing.share(text, title, null, link)
  if (!window.plugins || !window.plugins.socialsharing) { 
    const shareData = {
      title: title,
      text: text,
      url: link,
    };
    navigator.share(shareData).then(() => { alert('shared!') }).catch(console.error);
  } else {
    $(window)[0].plugins.socialsharing.share(text, title, null, link);
  }
    
}

const shareText = (text) => {
  if (!window.plugins || !window.plugins.socialsharing) { 
    const shareData = {
      // title: "MDN",
      text: text,
      //url: "https://developer.mozilla.org",
    };
    navigator.share(shareData).then(() => { alert('shared!') }).catch(console.error);
  } else {
    window.plugins.socialsharing.share(text, null, null, null)
  }
}

const shareWhatsApp = (phoneNumber, message) => {
  // alert(navigator.share);
  if (!window.plugins || !window.plugins.socialsharing) { 
    let text = encodeURIComponent(message); // Customize your message
    const url = encodeURIComponent(window.location.href); // Gets the URL of the current page
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${text}%20${url}`; // Creates the full WhatsApp URL
    window.open(whatsappUrl, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400');
  } else {
    window.plugins.socialsharing.shareViaWhatsAppToPhone(phoneNumber, text, null, null, function() {})
  }


}

const shareFb = (link) => {
  if (!window.plugins || !window.plugins.socialsharing) {
    window.open(link, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400');
  } else {
    window.cordova.InAppBrowser.open(link, '_system', 'location=no')
    
  }
}

export default {
  shareLink,
  shareText,
  shareWhatsApp,
  shareLink,
  shareFb
}
