import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import QRImage from './qr.png'
import './index.scss'
import $ from 'jquery';

const QRScanner = () => {
  const history = useHistory()

  const isValidHttpUrl = (urlPath) => {
    let url
    try {
      url = new URL(urlPath)
    } catch (_) {
      return false
    }
    return url.protocol === "http:" || url.protocol === "https:"
  }

  const onDone =(err, status) => {

    if (err) {

        // here we can handle errors and clean up any loose ends.
        console.error(err);
    }
    if (status.authorized) {
  
      startScan();
        // W00t, you have camera access and the scanner is initialized.
        // QRscanner.show() should feel very fast.
    } else if (status.denied) {
  
        // The video preview will remain black, and scanning is disabled. We can
        // try to ask the user to change their mind, but we'll have to send them
        // to their device settings with `QRScanner.openSettings()`.
        window.QRScanner.openSettings();
    } else {
        // we didn't get permission, but we didn't get permanently denied. (On
        // Android, a denial isn't permanent unless the user checks the "Don't
        // ask again" box.) We can ask again at the next relevant opportunity.
    }
}

  const displayContents = (err, text) => {
    
    if (err) {
  
    } else if (isValidHttpUrl(text)) {
      endScan()
      history.push('/')
      window.cordova.InAppBrowser.open(text, '_system', 'location=yes')
    } else {
      endScan()
      history.push('/')
    }
  }

  const startScan = () => {
   document.getElementsByTagName("body")[0].style.background = 'transparent';

    window.QRScanner.show();
    window.QRScanner.scan(displayContents);
    
  }

  const endScan = () => {
      window.QRScanner.destroy()
      document.getElementsByTagName("body")[0].style.background = 'white';

  }



  useEffect(() => {
  
    
    document.addEventListener('deviceready', () => {
  
      window.QRScanner.prepare(onDone); // show the prompt
    
    })
   
    return () => {
      endScan()
    }
  })

  return (
    <div className="qr-scanner-view">
    <img src={QRImage} alt="" />
    </div>
  )
}

export default QRScanner
