import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { advertisingAPI } from '../services'

 const initialState = {
  hasErrors: false,
  loading: false,
  count: 0,
  advertisements: [],
  filter: null
} 


export const advertisingSlice = createSlice({
  name: 'advertisements',
  initialState,
  reducers: {
    loadAdvertisements: state => {
      state.loading = true
   
    },
    loadAdvertisementsSuccess: (state, { payload }) => {
      state.advertisements = [...state.advertisements, ...payload.results]
      state.count = payload.count
      state.loading = false
      state.hasErrors = false

    },
    loadAdvertisementsFailure: state => {
      state.loading = false
      state.hasErrors = true
    },
    resetAdvertisements: state => {
      state.advertisements = []
    },
    setFilter: (state, { payload }) => {     
      state.filter = payload.filter;
    }
  }
})

export const fetchAdvertisements = (filter) => {
  return async dispatch => {
    dispatch(loadAdvertisements())
    try {
    
      const response = await advertisingAPI.getAdvertisements(filter)
    
      dispatch(loadAdvertisementsSuccess(response.data))
      return response
    } catch (error) {
     
      dispatch(loadAdvertisementsFailure())
      throw error
    }
  }
}

export const { loadAdvertisements, loadAdvertisementsSuccess, loadAdvertisementsFailure, resetAdvertisements, setFilter } = advertisingSlice.actions;
export const advertisementsSelector = state => state.advertisements
export default advertisingSlice.reducer


