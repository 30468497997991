import React from 'react'
import { shareAPI } from '../../services'
import './index.scss'
import ShowMore from 'react-show-more'
import { Divider } from 'primereact/divider'
import { Button } from 'primereact/button'
import BackButton from '../../components/backbutton'

import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { fetchConfig } from '../../store/configSlice' 

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {fab, faFacebook, faInstagram, faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {faPhoneVolume,faGlobe} from "@fortawesome/free-solid-svg-icons";

library.add(fab,faFacebook,faInstagram,faWhatsapp,faPhoneVolume,faGlobe); 

const AboutUs = () => {
  
  
  const openWhatsApp = (phoneNumber, message='Hola, le contacto desde la *app Qtokao Suite*, Me interesa obtener información de sus servicios para mi negocio!') => {
    shareAPI.shareWhatsApp(phoneNumber, message)
  }

  const dispatch = useDispatch()
  const { config } = useSelector(state => state.config )

  useEffect(() => { if(config == null || config == undefined) dispatch (fetchConfig()); }, [dispatch])

  const callSalon = (number) => {
    window.plugins.CallNumber.callNumber(console.log, console.log, number, true)
  }

  return (
    <div className="catalogue-detail">
    <BackButton /> 
    <div className="card">
      <br/> <br/>
      <h3 className="p-m-2" >
       Acerca de Nosotros
      
      </h3>
   
      <div className="p-m-2 description-detail" dangerouslySetInnerHTML={{__html: config.about_us}}></div>
       
   
      <h4 className="p-m-2">Contactos</h4>
      <Divider />
      <div className="p-m-2 bussiness-list">
       <div className="contact-item">
      
         <span className="socialMediaLinks">     
            <FontAwesomeIcon icon={['fab', 'whatsapp']}  className="p-button-rounded p-button-info p-mx-1" onClick={() => shareAPI.shareWhatsApp('+17867713658')}/>  
            <FontAwesomeIcon icon={['fas', 'phone-volume']} className="p-button-rounded p-button-info p-mx-1" onClick={() => callSalon('+17867713658')}/>
            <FontAwesomeIcon icon={['fab', 'facebook']} className="p-button-rounded p-button-info p-mx-1" onClick={()=>  shareAPI.shareLink(`https://www.facebook.com/qtokao`)}/>
            <FontAwesomeIcon icon={['fab', 'instagram']}  className="p-button-rounded p-button-info p-mx-1" onClick={()=>  shareAPI.shareLink(`https://www.instagram.com/qtokao`)}/>

            <FontAwesomeIcon icon={['fas', 'globe']} className="p-button-rounded p-button-info p-mx-1" onClick={()=> shareAPI.shareLink(`https://www.qtokao.com`)} />
            
          </span>
            
        </div>
      </div>
     
    </div>
    </div>
  )
}

export default AboutUs

