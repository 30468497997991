import {  createSlice } from '@reduxjs/toolkit';
import { userAPI } from '../services';

const initialState = {
    loading: false,
    hasErrors: false,
    user: null,
};

const userSignupSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        signUp: state => {
            state.loading = true
        },
        signUpSuccess: (state, { payload }) => {
            state.user = payload
            state.loading = false
            state.hasErrors = false
        },
        signUpFailure: state => {
            state.loading = false
            state.hasErrors = true
        },
    },
});

export const { signUpFailure, signUpSuccess, signUp } = userSignupSlice.actions;
export const userSelector = state => state.user;
export default userSignupSlice.reducer;

export function signUpUser(userData) {
    return async dispatch => {
        dispatch(signUp());

        try {
            const response = await userAPI.SignUpUserService(userData);
            const data = response.data.user;
            dispatch(signUpSuccess(data));
            return data;
        } catch (error) {
            dispatch(signUpFailure());
            throw error;
        }
    }
};

export function loginUser(loginData) {
    return async dispatch => {
        dispatch(signUp());

        try {
            const response = await userAPI.SignInUserService(loginData);
            const data = response.data.user;
            dispatch(signUpSuccess(data));
            return data;
        } catch (error) {
            dispatch(signUpFailure());
            throw error;
        }
    }
};
