
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import axios from "axios";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider'
import { addOneSalon, resetSalon, loadSalonsSuccess } from '../../store/salonSlice'
import { BASE_URL } from '../../config';

import './index.scss'
import translate from '../../i18n/translate';



/*onChange={(e) => searchSalons(e.target.value)}*/

export const Search = () => {
    const { salons, next, page, filter } = useSelector(state => state.salon)
    const dispatch = useDispatch();

    const [salonsSearch, setSalonsSearch] = useState([]);
    const [servicesSearch, setServicesSearch] = useState([]);   
    const [stateSearch, setStateSearch] = useState([]);
    const [municipalitySearch, setMunicipalitySearch] = useState([]);
    const [neighborhoodSearch, setNeighborhoodSearch] = useState([]);
    const [displayBasic2, setDisplayBasic2] = useState(false);
    const [loading, setLoading] = useState(true);
    const [textSearch, setTextSearch] = useState("");
    const [textMatch, setTextMatch] = useState("");

    const onClickSetVisibleSearch = (visible) => {
        setDisplayBasic2(visible)

    }

    async function onClickFilter(id, type) {

        //si selecciona salon
        var response = null;
        dispatch(resetSalon())
        if (type == 'B') // id salon
        {
            console.log(id + " id salon")
            response = await fetch(`${BASE_URL}/salon/salon/${id}`)
            const data = await response.json();
            let arr = [];
            arr.push(data);
            dispatch(addOneSalon(arr))
        }
        else if (type == 'S') // id service ToDo
        { response = await fetch(`${BASE_URL}/salon/salon/${id}`) }
        else if (type == 'P') // id state
        {
            console.log(id + " id state")
            response = await axios.get(`${BASE_URL}/salon/salon?is_active=true&state=${id}`)
            console.log(`${BASE_URL}/salon/salon?is_active=true&state=${id}`)
        }
        else if (type == 'M') // id municipality
        {
            console.log(id + " id municipality")
            response = await axios.get(`${BASE_URL}/salon/salon?is_active=true&municipality=${id}`)
            console.log(`${BASE_URL}/salon/salon?is_active=true&municipality=${id}`)
        }
        else if (type == 'N') // id neighborhood
        {   console.log(id + " id neighborhood")
            response = await axios.get(`${BASE_URL}/salon/salon/?is_active=true&neighborhood=${id}`) 
            console.log(`${BASE_URL}/salon/salon/?is_active=true&neighborhood=${id}`)
        }
        setDisplayBasic2(false)
        setSalonsSearch([])      
        dispatch(resetSalon())  
        dispatch(loadSalonsSuccess(response.data))
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            searchSalons(textSearch);
            //  searchServices(textSearch); hasta que no se asocien correctamente
            searchState(textSearch);
            searchMunicipality(textSearch);
            searchNeighborhood(textSearch);
        }, 1000)

        return () => clearTimeout(delayDebounceFn)

    }, [textSearch])

    const handleSearch = (text) => {
        setTextSearch(text.replace(/\s\s+/g, ' ').trim());
    }


    async function searchSalons(text) {
        if (!text && text.trim().lenght > 0) { setSalonsSearch([]); }
        else {
            setLoading(true);
            const response = await fetch(`${BASE_URL}/salon/salon?is_active=true&search=${text}`);
            const data = await response.json();
            const salons = data.results;
            setSalonsSearch([]);
            setSalonsSearch(salons);
            setLoading(false);
        }
    }

    //ToDo
    async function searchServices(text) {

        if (!text && text.trim().lenght > 0) { setServicesSearch([]); }
        else {
            setLoading(true);
            const response = await fetch(`${BASE_URL}/salon/service?is_active=true&search=${text}`)
            const data = await response.json();
            const services = data.results;
            setServicesSearch([]);
            setServicesSearch(services);
            setLoading(false);
        }
    }

    async function searchState(text) {

        if (!text && text.trim().lenght > 0) { setStateSearch([]); }
        else {
            setLoading(true);
            const response = await fetch(`${BASE_URL}/administrative-division/state/?search=${text}`)
            const data = await response.json();
            const states = data.results;
            setStateSearch([]);
            setStateSearch(states);
            setLoading(false);
        }
    }

    async function searchMunicipality(text) {

        if (!text && text.trim().lenght > 0) { setMunicipalitySearch([]); }
        else {
            setLoading(true);
            const response = await fetch(`${BASE_URL}/administrative-division/municipality/?search=${text}`)
            const data = await response.json();
            const municipalities = data.results;
            setMunicipalitySearch([]);
            setMunicipalitySearch(municipalities);
            setLoading(false);
        }
    }

    async function searchNeighborhood(text) {
        if (!text && text.trim().lenght > 0) { setNeighborhoodSearch([]); }
        else {
            setLoading(true);
            const response = await fetch(`${BASE_URL}/administrative-division/neighborhood/?search=${text}`)
            const data = await response.json();
            const neighborhoods = data.results;
            setNeighborhoodSearch([]);
            setNeighborhoodSearch(neighborhoods);
            setLoading(false);
        }
    }

    const isVocalAccents = (char) => {
        if (char) {
            var accents = ["á", "é", "í", "ó", "ú"];
            for (var i = 0; i < char.length; i++) {
                for (var j = 0; j < accents.length; j++) {
                    if (char[i] === accents[j]) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    function getIndicesOf(searchStr, str, caseSensitive) {

        var searchStrLen = searchStr != null && searchStr != "" ? searchStr.length : 0;

        if (searchStrLen == 0) {
            return [];
        }

        var startIndex = 0, index, indices = [];

        if (!caseSensitive) {
            str = str.toLowerCase();
            searchStr = searchStr.toLowerCase();
        }

        while ((index = str.indexOf(searchStr, startIndex)) > -1) {
            indices.push(index);
            startIndex = index + searchStrLen;
        }
        return indices;
    }

    const formatLabel = (text, textAux) => {
        if (textSearch == "")
            return <></>;

        if (!isVocalAccents(textSearch)) {
            text = removeAccents(text);
        }
        const val = textSearch.split(' ');
        var redPositions = [];

        // below my item Description is split based on my search
        // terms "visit" n "hour" and then stored in vals array
        const vals = val.map(valu => {
            var regex = new RegExp(`(${valu})`, 'gim');
            return text.split(regex);
        })

        var rows = Object.keys(vals).length;

        for (var i = 0; i < rows; i++) {

            var matchPart = vals[i][1];

            if (matchPart == null)
                continue;

            var lenghtPart = matchPart.length;

            var arrIndexCurrent = getIndicesOf(matchPart, text);
            redPositions = redPositions.concat(arrIndexCurrent);
            for (var j = 0; j < arrIndexCurrent.length; j++) {
                var currPos = arrIndexCurrent[j];
                for (var k = currPos; k < currPos + lenghtPart; k++) {
                    redPositions.push(k);
                }
            }
        }

        redPositions = redPositions.filter(function (item, pos) {
            return redPositions.indexOf(item) == pos;
        })

        let render = [];
        if (!isVocalAccents(textSearch)) {
            render = [...textAux];
        } else {
            render = [...text];
        }

        return (<span>{render.map((part, index) => (redPositions.includes(index)) ?
            <span style={{ color: '#cd2c00' }}>{part}</span> :
            part
        )}
        </span>);
    }

    return (
        <>
            <Button label="Buscar" icon="pi pi-search" className="p-button-text p-d-flex p-flex-column p-button-bottom" onClick={() => onClickSetVisibleSearch(true)} />

            <Dialog visible={displayBasic2} style={{ width: '100%' }} /* footer={renderFooter('displayBasic2')} */ onHide={() => onClickSetVisibleSearch(false)}>
                <InputText type="text" autoFocus onFocus={e => e.currentTarget.select()} className="p-inputtext-lg block input-search-w" placeholder='Negocios, Barrios, Municipios..'/* {translate('what_searching')}  */ onChange={(e) => handleSearch(e.target.value)} />

                {
                    !loading && salonsSearch && salonsSearch.length > 0 && textSearch ? <Divider align="left">
                        <div className="inline-flex align-items-center">
                            {/*    <i className="pi pi-briefcase mr-2"></i> */}
                            <b> Negocios</b>
                        </div>
                    </Divider> : <>  </>

                }
                {
                    !loading && salonsSearch && salonsSearch.map((item, index) => (
                        <Button label={formatLabel(item.name, item.name)} className="p-button-text" onClick={() => onClickFilter(item.id, 'B')} />
                    ))
                }



 
             { /*  {
                    !loading && servicesSearch && servicesSearch.length > 0 && textSearch ? <Divider align="left">
                        <div className="inline-flex align-items-center">
                           
                            <b> Servicios</b>
                        </div>
                    </Divider> : <>  </>

                }

                {
                    !loading && servicesSearch && servicesSearch.map((item, index) => (
                        <Button label={formatLabel(item.name, item.name)} className="p-button-text" onClick={() => onClickFilter(item.id, 'S')} />
                    ))
                }
            }*/}

{
                    !loading && stateSearch && stateSearch.length > 0 && textSearch ? <Divider align="left">
                        <div className="inline-flex align-items-center">
                            {/*    <i className="pi pi-briefcase mr-2"></i> */}
                            <b> Provincias</b>
                        </div>
                    </Divider> : <>  </>

                }

                {
                    !loading && stateSearch && stateSearch.map((item, index) => (
                        <Button label={formatLabel(item.name, item.name)} className="p-button-text" onClick={() => onClickFilter(item.id, 'P')} />
                    ))
                }


                {
                    !loading && municipalitySearch && municipalitySearch.length > 0 && textSearch ? <Divider align="left">
                        <div className="inline-flex align-items-center">
                            {/*    <i className="pi pi-briefcase mr-2"></i> */}
                            <b> Municipios</b>
                        </div>
                    </Divider> : <>  </>

                }

                {
                    !loading && municipalitySearch && municipalitySearch.map((item, index) => (
                        <Button label={formatLabel(item.name, item.name)} className="p-button-text" onClick={() => onClickFilter(item.id, 'M')} />
                    ))
                }

                {
                    !loading && neighborhoodSearch && neighborhoodSearch.length > 0 && textSearch ? <Divider align="left">
                        <div className="inline-flex align-items-center">
                            {/*    <i className="pi pi-briefcase mr-2"></i> */}
                            <b> Reparto</b>
                        </div>
                    </Divider> : <>  </>

                }

                {
                    !loading && neighborhoodSearch && neighborhoodSearch.map((item, index) => (
                        <Button label={formatLabel(item.name, item.name)} className="p-button-text" onClick={() => onClickFilter(item.id, 'N')} />
                    ))
                }
            </Dialog>

        </>
    )
}
export default Search;