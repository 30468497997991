import { LOCALES } from "../locales";

export default 
{
[LOCALES.SPANISH]:
    {
        'increment': 'incrementar',
        'decrement': 'disminuir',
        'signup_or_signin': 'Regístrese o Acceda',
        'continue_with_facebook': 'Continua con Facebook',
        'continue_with_google': 'Continua con Google',
        'continua_con_email': 'Continua con el Email',
        'or': 'o',
        'name': 'Nombre',
        'last_name': 'Apellido',
        'email': 'Email',
        'contrasena':  'Contraseña',
        'name_required_validation': 'El Nombre es requerido',
        'email_required_validation': 'El Email es requerido',
        'email_invalid_validation': 'Direccion Email incorrecta. E.j. example@email.com',
        'password_required_validation': 'La Contraseña es requerida',
        'phone_required_validation': 'El telefono es requerido',
        'last_name_required_validation': 'El Apellido es requerido',
        'set_phone_number': 'Agregar telefono',
        'skip_phone_number': 'Agregar mas tarde',
        'sign_in': 'Accede',
        'inscribe': 'Registre su negocio',
        'welcome': 'Bienvenid@ a Qtokao',
        'have_account': '¿Ya tienes cuenta?',
         'confirm' : 'Confirmar',
         'cancel' : 'Cancelar',
         'start_session' : 'Inicia Sesión',
         'what_searching' : 'Negocios o Servicios'  // , Barrios o Municipios
    }
   
}