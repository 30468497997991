import React, { useEffect, useState } from 'react'
import './index.scss'
import { useSelector, useDispatch } from 'react-redux'
import { fetchSalons, setPage } from '../../store/salonSlice'
import { fetchAdvertisements } from '../../store/advertisingSlice'
import Grid from './grid'
import NavBar from '../../components/navbar'
import Promotions from './promotions'
import promo1 from './promo1.jpg'
import promo2 from './promo2.jpg'
import Categories from '../../components/categories'
import Inscribe from '../../components/inscribe'
import { InView } from 'react-intersection-observer'


const Loader = ({ hasNext, onViewChange }) => {
  if (hasNext === true) {
    return (
      <InView as="div" onChange={(inView, entry) => onViewChange(inView)}>
        <div className="grid-loader">
        <i className="pi pi-spin pi-spinner" style={{'fontSize': '2em', 'color': '#cd2c00'}}></i>
        </div>
      </InView>
    )
  } else {
    return (<></>)
  }
}

const CatalogueView = () => {
  const dispatch = useDispatch()
  const [inscribeVisible, setInscribeVisible] = useState(false)
  const { salons, next, loading, page, filter } = useSelector(state => state.salon)
  
  const { advertisements } = useSelector(state => state.advertising )

  useEffect(() => {
    if(advertisements.length== 0)
     dispatch (fetchAdvertisements()); 
    }, [dispatch])


  const loadSalons = (page, filter) => { 
      dispatch(fetchSalons(page,filter))  
  }

  const loadNextPage = () => {
    if (next === false || loading === true) {
      return;
    }
      dispatch(setPage())  
    
      loadSalons(page, filter)   
  }

  const loadScroll = load => {
    if (load === false) {
      return;
    }
    loadNextPage()
  }

  const inscribeClick = () => {
    setInscribeVisible(!inscribeVisible);
  }


  return (
    <div className="catalogue"> 
    <NavBar clickHandler={ () => inscribeClick() }/>
      { advertisements.length !== 0 ? (<Promotions advertisements={advertisements} />) : null }  
    <Categories />
    <div className="catalogue-view" id="scrollableDiv"> 
      <Grid items={salons} filter={filter}/>
      <Loader hasNext={next} onViewChange={(inView) => loadScroll(inView)} />
    </div>
    <Inscribe visible={inscribeVisible} onHide={() => setInscribeVisible(false)}/>
    </div>
  )
}

export default CatalogueView
