import './categories.scss'
import { useSelector, useDispatch } from 'react-redux'
import React, { useState } from "react";
import { resetSalon, setFilter, fetchSalons } from '../../store/salonSlice'
import { Carousel } from 'primereact/carousel'
import { DataView } from 'primereact/dataview';
import $ from 'jquery';

const Categories = () => {
  const dispatch = useDispatch()
  const { categories, filter, page } = useSelector(state => state.salon)


  /*Resalta la categoría seleccionada*/
  const changeStyle = (categoryId,srcPrev,srcImageSelected) => {
   // var newSrc = $('#categories-carousel_img' + categoryId).attr('src').replace( srcPrev, srcImageSelected);
  
    $("#categories-carousel_img" + categoryId).attr("src", srcImageSelected);
    
  //  document.getElementById("categories-carousel_img" + categoryId).src =srcImageSelected;

    $("[id^='categories-carousel_img']").each(function () {
      for(var i = 0; i< categories.length; i ++){
     
      if ($(this).attr("src") == categories[i].image_selected && categories[i].image_selected != srcImageSelected ) // quito imagen seleccionada a todos los iconos
       {$(this).attr('src', categories[i].image);
      
     //  $(this).attr('srcset', categories[i].image); 
    }
      }
    });
    
  };

  const filterSalons = (item) => {
    
    dispatch(resetSalon())
    dispatch(setFilter({ filter: item.filter }))
    dispatch(fetchSalons(1, item.filter))
    /*marcar categoría*/
    changeStyle(item.filter.category, item.image, item.image_selected);

  }


  const ItemTemplate = (item) => {

    return (
      <div className="categories-carousel_item" onClick={() => filterSalons(item)}>
        <img key={item.image} id={"categories-carousel_img" + item.filter.category} src={item.image} alt="Test test" className="image" />
        <div></div>
        <p id={"category_carousel_icons" + item.filter.category} class="title-cat" >{item.title} </p>
      </div>
      
    )
  }

  return (
    <div className="dataview-demo">
    <div className="card">
        <DataView value={categories} layout='grid' rows={5} className="categories-carousel" 
                itemTemplate={ItemTemplate} />
    </div>
</div>

    /*<Carousel  value={categories}  numVisible={4}  className="categories-carousel"   itemTemplate={ItemTemplate} indicatorsContentClassName="hidden" />*/
  )
}

export default Categories
