import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import salonSlice from  './salonSlice'
import advertisingSlice from './advertisingSlice'
import configSlice from './configSlice'
import SignUpSlice from './userSlice'

const rootReducer = combineReducers({
  salon: salonSlice,
  advertising: advertisingSlice,
  config: configSlice,
  user: SignUpSlice
})

export default configureStore({
  reducer: rootReducer
})
