import img0 from './assets/1-01.png'
import img1 from './assets/1-02.png'
import img2 from './assets/1-03.png'
import img3 from './assets/1-04.png'
import img4 from './assets/1-05.png'
import img5 from './assets/1-06.png'
import img6 from './assets/1-07.png'
import img7 from './assets/1-08.png'
import img8 from './assets/1-09.png'
import img9 from './assets/1-10.png'
import img10 from './assets/1-11.png'
import img11 from './assets/1-12.png'
import img12 from './assets/1-13.png'

//selected images
import imgS0 from './assets/1-01s.png'
import imgS1 from './assets/1-02s.png'
import imgS2 from './assets/1-03s.png'
import imgS3 from './assets/1-04s.png'
import imgS4 from './assets/1-05s.png'
import imgS5 from './assets/1-06s.png'
import imgS6 from './assets/1-07s.png'
import imgS7 from './assets/1-08s.png'
import imgS8 from './assets/1-09s.png'
import imgS9 from './assets/1-10s.png'
import imgS10 from './assets/1-11s.png'
import imgS11 from './assets/1-12s.png'
import imgS12 from './assets/1-13s.png'

export default [
 { image: img0, image_selected: imgS0,  title: '    Barbería    ', filter: {category: 1}},
  { image: img4, image_selected: imgS4, title: 'Cejas y Pestañas', filter: {category: 4}},
  { image: img3, image_selected: imgS3, title: '  Cuidado Piel  ', filter: {category: 3}},
  { image: img8, image_selected: imgS8, title: '   Depilación   ', filter: {category: 5}},
  { image: img1, image_selected: imgS1, title: '   Peluquería   ', filter: {category: 2}},
  { image: img6, image_selected: imgS6, title: '   Maquillaje   ', filter: {category: 6}},
  { image: img2, image_selected: imgS2, title: '    Manicura    ', filter: {category: 7}},
  { image: img5, image_selected: imgS5, title: '    Masajes     ', filter: {category: 8}},
  { image: img7, image_selected: imgS7, title: '    Tatuajes    ', filter: {category: 9}},
  { image: img11,image_selected: imgS11,title: '    Fitness     ', filter: {category: 12}},
  { image: img12,image_selected: imgS12, title:'      Yoga      ', filter: {category: 13}},
  { image: img9, image_selected: imgS9, title: '      Otros     ', filter: {category: 10}},
  { image: img10,image_selected: imgS10, title:'      Todos     ', filter: {}}
]
