import React from 'react'
import './index.scss'
import { useHistory } from 'react-router-dom'
import { Button } from 'primereact/button'

const BackButton = () => {
  const history = useHistory()

  const back = () => {
    history.push("/");
  }

  return (
    <Button icon="pi pi-arrow-left" className="p-button-rounded back-button" onClick={() => back()} />
  )
}

export default BackButton
