import axios from "axios";
import { BASE_URL } from "../config";

const getAdvertisements = async (filter={}) => {
 
  let urlFilter = Object.keys(filter).map(f => {
    return `${f}=${filter[f]}`;
  }).join('&')
  return axios.get(`${BASE_URL}/enterprise/advertising/?is_active=true&${urlFilter}`)
}

export default {
    getAdvertisements
}

