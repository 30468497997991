import './index.scss'

import React, { useEffect, useState } from 'react'
import { Divider } from 'primereact/divider'
//PrimeReact
import { Avatar } from 'primereact/avatar';
import BackButton from '../../components/backbutton'
import { Badge } from 'primereact/badge';

import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { BASE_URL } from '../../config'
const Perfil = () => {
  const dispatch = useDispatch();
  const [userPerfil, setUserPerfil] = useState({})
  const { access_token } = useParams();

useEffect(() => {
 
  if(access_token != undefined && access_token != null && access_token != "") 
  {

    fetchUser();
  }
   }, []);


   const fetchUser  = () => {
  
  fetch(`${BASE_URL}/user/profile`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json',
      'Authorization': `Bearer ${access_token}`}
  })
  .then((response) => response.json())
  //Then with the data from the response in JSON...
  .then((data) => {
    setUserPerfil(data);
  
  })
  //Then with the error genereted...
  .catch((error) => {
    console.error('Error:', error);
  }); 

}  

  return (
    
    <div className="catalogue-detail">
    <BackButton /> 
    
    <div className="card">
    <div className="name_user_photo">
    <br/> <br/> 
      <h3 className="p-m-2" >
      {userPerfil.name}  {userPerfil.last_name}
      </h3>
      <Avatar image={userPerfil.photo} className="mr-2" size="xlarge" shape="circle"/>
      
      </div>
      <br/>
      <Divider />
      <div className="stats_user">
      <i className="p-m-2 pi pi-bell mr-4 p-text-secondary p-overlay-badge" style={{ fontSize: '2rem' }}><Badge value="0" severity="danger" ></Badge></i> <span> Sorteos pendientes </span>
      </div>
      <br/> 
      <div className="stats_user">
      <i className="p-m-2  pi pi-calendar mr-4 p-text-secondary p-overlay-badge" style={{ fontSize: '2rem' }}><Badge value="0" severity="danger" ></Badge></i><span> Citas pendientes </span>
      </div>
      <Divider />
      <br/> 
      <h3 className="p-m-2" >
      {userPerfil.phone_number}
     
      </h3>
    
    </div>
    </div>
  )
}

export default Perfil

