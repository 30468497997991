import axios from 'axios';
import { BASE_URL } from '../config';
const PROTOCOL = 'https';
const DOMAIN = BASE_URL;
const BASE_URL_ = `${PROTOCOL}://${DOMAIN}`;

const SIGNUP_PATH = 'user/user_register/';
const SIGNIN_PATH = 'user/user/user_login/';

const SignUpUserService = (data={}) => {
    return axios.post(`${BASE_URL_}/${SIGNUP_PATH}`, data);
};

const SignInUserService = (data={}) => {
    return axios.post(`${BASE_URL_}/${SIGNIN_PATH}`, data);
};

export default {
    SignUpUserService,
    SignInUserService
};
