import './index.scss'
import '../commoncss/css.scss'
import React, { useState, useEffect } from 'react'
//import React, { Component } from "react";
import { connect } from "react-redux";
import {useForm} from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux'
import {NotificationManager} from 'react-notifications';

import { fetchConfig } from '../../store/configSlice' 

import axios from "axios";
 

import { createSalon } from "../../store/salonSlice";

import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { BASE_URL } from '../../config';
 

   const  Inscribe = ({ visible, onHide }) => {

    const { register, handleSubmit, formState: { errors }} = useForm();   

    const dispatch = useDispatch()
  const { config } = useSelector(state => state.config )

  useEffect(() => { dispatch (fetchConfig()); }, [dispatch])

      const peticionPost=async(data)=>{

      axios.post(`${BASE_URL}/salon/salon/`, {
        
          "name": data.name,
          "time_zone": "Africa/Abidjan",
          "default_language": "en",
          "address": "",
          "latitude": 0,
          "longitude": 0,
          "hour_format": "%H:%M:%S",
          "date_format": "%Y-%m-%d",
          "is_active": false,
          "pending": true,
          "description": data.description,
          "phone_number": data.phone_number,
          "landline_phone_number": null,
          "email": "user@example.com",
          "automatic_confirmation": true,
          "reservation_before": 0,
          "modification_before": 0,
          "logo": null,
          "facebook": null,
          "instagram": null,
          "youtube": null,
          "municipality": null
        }, {
              headers: {
                  'Content-Type': 'application/json;charset=UTF-8'
              }
      })
      .then(response => { 
       
        NotificationManager.success('En breve un comercial de Qtokao le contactará al teléfono indicado','Hemos recibido tu solicitud',8000);
       document.getElementById("side-bar-inscribe").style.display = 'none';
        
      })
      .catch(error => {
        NotificationManager.error('Tenemos dificultades ahora, contáctanos directamente desde el botón NOSOTROS','Contacta al equipo de Qtokao',6000);
      });  

    }
   const [data, setData]=useState([]);
    const [name,setName] = useState("");
    const [description,setDescription] = useState("");
    const [phone,setPhone] = useState("");
  
    const hideInscribe = evt => {
      evt.preventDefault();
      onHide();
    }



  return (
    
    <Dialog visible={visible} onHide={onHide} position="center" id="side-bar-inscribe" style={{zIndex: 1, height: 'auto'}}  >
      <h2>Registre su negocio &nbsp;<i id="icon-form" className="pi pi-home p-mr-2"></i></h2>
      <hr/>
      <form onSubmit = {handleSubmit(peticionPost)} className="p-fluid">

        <div className="p-field">
          <span className="p-float-label">
            <InputText onChange ={(event) => setName(event.target.value)} id="name" name="name" className="text-comment"  placeholder="Nombre del negocio*"
            {...register("name", {
                required: "Required",
             })}/>

              {/* <label htmlFor="name" >Nombre del Salon*</label> */}
          </span>
        </div>

        <div className="p-field">
          <span className="p-float-label">
            <InputTextarea onChange ={(event) => setDescription(event.target.value)} className="text-comment" id="description" name="description" placeholder={config.description_salon_inscribe} rows={5}  
            {...register("description", {
                required: "Required",
             })}/>
            {/* <label htmlFor="description" >Descripcion*</label> */}
          </span>
        </div>

        <div className="p-field">
          <span className="p-float-label">
            <InputText onChange ={(event) => setPhone(event.target.value)} id="phone_number" name="phone_number" className="text-comment" placeholder="Teléfono*"
             {...register("phone_number", {
              required: "Required",
             })}/>
          {/*   <label htmlFor="phone_number" >Telefono*</label> */}
          </span>
        </div>
<br/>
        <div className="p-d-flex" id="inscribe-btn-div">
        <Button label="Cancelar" className="p-button inscribe-btn" icon="pi pi-times" id="cancel-btn"  iconPos="right" onClick={hideInscribe} />
        <div id="mid-div"></div>
        <Button label="Confirmar" className="p-button inscribe-btn" icon="pi pi-check" id="confirm-btn" iconPos="right"  />
        </div>
      </form>
    </Dialog>
  );
};


export default connect(null, { createSalon })(Inscribe);
